@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/vanilla");

body {
  /* background-image: url(./assets/bg.png); */
  background-image: url(./assets/bg2.svg);
  background-size: cover;
  background-position: center;
  background-color: #ffdd00;
  font-family: "Vanilla", sans-serif;
  animation: scale-background 20s infinite alternate;
}

.bgother {
  background-image: url(./assets/otherbg.svg);
  background-size: cover;
  background-position: center;
  background-color: #ffdd00;
  animation: scale-background 20s infinite alternate;
}

@keyframes rotate {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

.rotate-animation {
  animation: rotate 0.15s infinite alternate;
}

/* Add this to your CSS file */
.scale-animation {
  transition: transform 0.3s ease;
}

.scale-animation:hover {
  transform: scale(1.1);
}

@keyframes scale-background {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 145%;
  }
  100% {
    background-size: 100%;
  }
}

@keyframes leftright {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

.leftright {
  animation: leftright 0.5s infinite alternate;
}

/* Add this CSS to your stylesheet */
.flip-animation {
  animation: flip 5s infinite alternate;
}

@keyframes flip {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(-1);
  }
}

.bounce-animation {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(30px);
  }
  60% {
    transform: translateY(15px);
  }
}
